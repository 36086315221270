// @flow
import * as React from "react";
import type {Price} from "../models/Product";
import {BrandContext} from "./App";
import i18n from "../i18n/i18n";

type Props = {
	price: Price
};

export const Currency = (props: Props) => {
	const brand = React.useContext(BrandContext);
	const getFormat = (priceValue, theme) => {
		switch (theme) {
			case "noGrouping":
				return {
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
					useGrouping: false,
				};
			case "volvo":
				return {
					...{
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					},
					...removeFractionDigitsForEdge(priceValue)
				};
			default:
				return {
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				};
		}
	};

	const removeFractionDigitsForEdge = priceValue => {
		const significantPriceDigits = Math.round(priceValue).toString().length;
		return {maximumSignificantDigits: significantPriceDigits};
	};

	const currencyConverter = (price: Price, theme: string, lang: string = i18n.language || "de") => {
		return new Intl.NumberFormat(lang, {
			...getFormat(price.value, theme),
			...{style: "currency", currency: price.currency}
		}).format(price.value);
	};

	return <React.Fragment>
		{currencyConverter(props.price, brand)}
	</React.Fragment>;
};
