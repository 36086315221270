import React, {useEffect} from "react";
import "./BrandInstancPage.scss";
import type {GarageDealership} from "../models/GarageDealership";
import Placeholder2x1 from "../assets/images/ph2x1.png";
import {Loading} from "./Loading";
import type {translate} from "../i18n/i18n";

export type BrandInstanceProps = {
	brandInstances: GarageDealership[],
	shouldRender: boolean,
	selectBrand: number => void,
	t: translate,
};

const BrandInstancePage = (props: BrandInstanceProps) => {
	const {shouldRender, brandInstances, selectBrand} = props;
	useEffect(() => {
		if (brandInstances.length === 1) {
			selectBrand(brandInstances[0].dealerId);
		}
	}, [brandInstances, selectBrand]);

	const renderBrands = (brands: GarageDealership[]) => {
		return brands.map(brand => {
			const brandLogo = {
				backgroundImage: `url(${brand.manufacturerLogoUrl})`
			};
			const brandSize = {
				flex: brands.length <= 4
					? "0 0 45%"
					: brands.length <= 6
						? "0 0 30%"
						: "unset"
			};
			return (
				<div key={brand.dealerId} className="pos-brand" role={"button"}
					 onClick={() => props.selectBrand(brand.dealerId)} style={brandSize}>
					<img src={Placeholder2x1} alt=""/>
					<div className="pos-brandLogo" style={brandLogo}/>
				</div>
			);
		});
	};

	return shouldRender
		? <div className="pos-brand-instance">
			<div className="pos-brand-title">{props.t("brandSelector.title")}</div>
			<div className="pos-brands">
				{renderBrands(brandInstances)}
			</div>
		</div>
		: <Loading/>;
};

export default BrandInstancePage;
