import {connect} from "react-redux";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import type {ApplicationState} from "../reducers";

function mapStateToProps(state: ApplicationState) {
	if (process.env.NODE_ENV !== "test") {
		i18n.changeLanguage(state.i18n.languageCode);
	}
	return {
		i18n
	};
}

export default connect(mapStateToProps)(I18nextProvider);
