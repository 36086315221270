// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {CategoryPage} from "../components/CategoryPage";
import {getCategory} from "../actions/content";

type ProductsPageContainerProps = {
	onLoad: () => void,
};

function mapStateToProps(state: ApplicationState, ownProps: ProductsPageContainerProps) {
	let currentCategory: Category = state.content.selectedCategory && state.content.selectedCategory.ownCount ? {...state.content.selectedCategory} : null;
	if (currentCategory) {
		currentCategory.title = ownProps.t("misc.others");
		currentCategory.categories = [];
	}

	return {
		mainCategories: (state.content.mainCategories.length < 8 && currentCategory) ? [...state.content.mainCategories, currentCategory] : state.content.mainCategories,
		otherCategories: (state.content.mainCategories.length >= 8) && currentCategory ? [...state.content.otherCategories, currentCategory] : state.content.otherCategories,
		vehicleOption: state.filter.vehicleOption,
		isIdle: !state.content.requestQueue.length,
		highlightColor: state.config.highlightColor,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: ProductsPageContainerProps) {
	return {
		onLoad: selectedCategoryKey => dispatch(getCategory(selectedCategoryKey))
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CategoryPage)));
