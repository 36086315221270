/* eslint-disable jsx-a11y/anchor-is-valid,react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./CategorySelector.scss";
import type {Category} from "../models/Category";
import {Link} from "react-router-dom";

type Props = {
	onSelect: () => void,
	onClose: () => void,
	shouldOpen: boolean,
	categories: Category[],
};
export const CategorySelector = (props: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const deps = props.shouldOpen !== isOpen;
	React.useEffect(() => {
		setTimeout(() => {
			setIsOpen(props.shouldOpen);
		}, 500);
	}, [deps, props.categories]);

	const renderCategory = (category: Category) => {
		const targetUrl = category.categories.length
			? `/category?category=${category.key}`
			: `/product/?category=${category.key}`;

		return <div key={category.key} data-testid={"subCategory"}>
			<Link onClick={() => props.onSelect()} to={targetUrl}>
				<span data-testid={"subCategoryTitle"}>
					{category.title}
					<span className={"amount"} data-testid={"subCategoryCount"}>
						({category.count || 0})
					</span>
				</span>
			</Link>
		</div>;
	};

	return (
		<aside className={`CategorySelector ${(props.shouldOpen === isOpen) && isOpen === true ? "open" : ""}`}
					 data-testid={"subCategoryMenu"}
					 onClick={() => props.onClose()}
		>
			{props.categories.length && <div onClick={e => e.stopPropagation()}>
				<div>
					{props.categories.map(category => renderCategory(category))}
				</div>
			</div>}
		</aside>
	);
};
