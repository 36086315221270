/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./SwimLane.scss";
import useEmblaCarousel from "embla-carousel-react";

type SwimLaneProps = {
	children: any;
};

const SwimLane = (props: SwimLaneProps) => {
	const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

	const [viewportRef, embla] = useEmblaCarousel({
		dragFree: true,
		loop: false,
		align: "start",
	});

	const scrollPrev = React.useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = React.useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = React.useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	React.useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [embla, onSelect]);

	return <div className="swimLane">
		<div ref={viewportRef} className="embla">
			<div className="embla__container">
				{props.children}
			</div>
			<button className={"carouselBtn"} type={"button"} onClick={scrollPrev} disabled={!prevBtnEnabled}/>
			<button className={"carouselBtn"} type={"button"} onClick={scrollNext} disabled={!nextBtnEnabled}/>
		</div>
	</div>;
};

export default SwimLane;
