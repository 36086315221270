// @flow
import * as React from "react";
import "./TextBlock.scss";

type Props = {
	title: string,
	children: React.Element,
	onClick?: () => void,
	className?: string,
};
const TextBlock = (props: Props) => {
	if (!props.onClick) {
		return <div className={`TextBlock ${props.className}`}>
			<div className="title">{props.title}</div>
			<div className="content">{props.children}</div>
		</div>;
	}

	return <div onClick={() => props.onClick()} className={`TextBlock ${props.className}`}>
		<div className="title">{props.title}</div>
		<div className="content">{props.children}</div>
	</div>;
};

export default TextBlock;
