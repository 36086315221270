// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {setSearchPhrase} from "../actions/filter";
import {Filter} from "../components/Filter";
import { customHistory } from "../helper/customHistory";

type CategoryPageContainerProps = {};

function mapStateToProps(state: ApplicationState, ownProps: CategoryPageContainerProps) {
	return {
		fuzzy: state.filter.fuzzy,
		categoryPath: state.content.selectedCategory ? [state.content.selectedCategory.title] : [],
		amount: customHistory.location.pathname.startsWith("/category") ? (state.content.selectedCategory || {}).count : state.filter.amount,
		contrastColor: state.config.contrastColor,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CategoryPageContainerProps) {
	return {
		resetFuzzy: () => dispatch(setSearchPhrase("")),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filter));
