// @flow
import * as React from "react";
import "./ProductsDetailPage.scss";
import type {ProductDetail} from "../reducers/contentReducer";
import {Loading} from "./Loading";
import {useLocation} from "react-router";
import ContrastColorUpdater from "./ContrastColorUpdater";
import TextBlock from "./TextBlock";
import SwimLane from "./SwimLane";
import {ProductTile} from "./ProductTile";
import {useTranslation} from "react-i18next";
import {PriceBox} from "./PriceBox";
import {Gallery} from "./Gallery";
import type {Product} from "../models/Product";

type ProductsDetailPageProps = {
    onLoad: string => void,
    cleanup: () => void,
    productId: string,
    currentProduct: ProductDetail,
    addToWishList: () => void,
    removeFromWishList: () => void,
    isOnWishList: boolean,
    highlightColor: string,
    contrastColor: string,
    disableWishlist: boolean,
    wishlist: Array<Product>,
};

type SectionValue = "description" | "specifications";

const ProductsDetailPage = ({
                                addToWishList,
                                cleanup,
                                currentProduct,
                                disableWishlist,
                                highlightColor,
                                isOnWishList,
                                onLoad,
                                productId,
                                removeFromWishList,
                                wishlist
                            }: ProductsDetailPageProps) => {
    const location = useLocation();
    const productIdFromUrl = (new URLSearchParams(location.search)).get("productId");

    const [activeSection, setActiveSection] = React.useState<SectionValue>("description");

    const handleSetActiveSection = (section: SectionValue) => {
        if (activeSection !== section) {
            setActiveSection(section);
        } else {
            setActiveSection("");
        }
    };

    const sections = currentProduct ? [
        {
            tag: "description",
            label: "productDetailsPage.description",
            value: currentProduct.product.description,
            testId: "description"
        },
        {
            tag: "specifications",
            label: "productDetailsPage.specifications",
            value: currentProduct.product.fitments,
            testId: "fitments"
        },
    ] : [];

    const {t} = useTranslation();

    React.useEffect(() => {
        onLoad(productIdFromUrl);
        return () => cleanup;
    }, [productId, onLoad, cleanup, productIdFromUrl]);

    if (!currentProduct) {
        return <Loading/>;
    }

    return <div className="page productsDetailPage" data-testid={"productDetailPage"}>
        <ContrastColorUpdater/>
        <div className="productDetail">
            <Gallery images={currentProduct.attachments.images}
                     videos={currentProduct.attachments.videos}/>
            <div className="productInfo">
                <div className="productTitle" data-testid="title">
                    <h1>{currentProduct.product.name}</h1>
                    <h5>Artikelnummer: {currentProduct.product.articleNumber}</h5>
                </div>
                <div className="productPrice">
                    <div className="price">
                        <div className="priceBox" data-testid={"price"}>
                            <PriceBox price={currentProduct.product.price}
                                      rrp={currentProduct.product.recommendedRetailPrice}
                                      unitPrice={currentProduct.product.unitPrice}
                                      withMontage={!currentProduct.product.distributionChannels.shipping && !currentProduct.product.distributionChannels.pickup}
                            />
                        </div>
                    </div>
                </div>
                {!disableWishlist && <div className="wishlist">
                    <button
                        className={`wishlistButton ${isOnWishList ? "active" : ""}`}
                        onClick={isOnWishList ? removeFromWishList : addToWishList}
                        disabled={disableWishlist}
                        data-testid={!isOnWishList ? "addBookmark" : "removeBookmark"}
                    >
                        {isOnWishList ? t("productDetailsPage.removeFromWishList") : t("productDetailsPage.addToWishList")}
                    </button>
                </div>}
                {sections.map(section => {
                    return <div key={section.tag} className="productSection">
                        <button
                            className={`sectionButton ${activeSection === section.tag ? "active" : ""}`}
                            onClick={() => handleSetActiveSection(section.tag)}
                        >
                            {t(section.label)}
                        </button>
                        <div dangerouslySetInnerHTML={{__html: section.value}} data-testid={section.testId}
                             className={`sectionContent ${activeSection === section.tag ? "active" : ""}`}/>
                    </div>;
                })}
            </div>
        </div>
        <div className="crossSelling" data-testid={"crossSelling"}>
            <TextBlock title={t("productDetailsPage.crossSelling")}>
                <SwimLane>
                    {currentProduct.relatedProducts.map(product => <ProductTile
                        key={product.id}
                        productItem={product}
                        highlightColor={highlightColor}
                        isOnWishList={wishlist.some(wish => wish.id === product.id)}
                        disableWishlist={disableWishlist}
                    />)}
                </SwimLane>
            </TextBlock>
        </div>
    </div>;
};

export default ProductsDetailPage;
