import type {ApplicationState} from "../reducers";
import {CONFIG_ID_TYPE, INTENT, ProductService} from "../api/ProductService";
import type {Dispatch} from "redux";
import {brandInstanceGotten, brandInstanceReset, brandInstanceSet} from "../reducers/configReducer";

export const GET_BRAND_INSTANCE_ERROR = "GET_BRAND_INSTANCE_ERROR";

export const getBrandInstances = configId => {
	return (dispatch, getState: () => ApplicationState) => {
		const api = new ProductService(configId, CONFIG_ID_TYPE.GARAGE, INTENT.DEFAULT, getState().i18n.languageCode);
		return api.getBrands().then(
			response =>
				dispatch(brandInstanceGotten(response)),
			error => dispatch({
				type: GET_BRAND_INSTANCE_ERROR,
				msg: error
			})
		);
	};
};

export const setBrandInstance = brandInstance => {
	return (dispatch: Dispatch<any>) => {
		dispatch(brandInstanceSet(brandInstance));
	};
};

export const resetBrandInstance = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(brandInstanceReset());
	};
};
