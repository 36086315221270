// @flow
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {contrastColorCalculated} from "../reducers/configReducer";

const ContrastColorUpdater = ({selector = "#colorPicker", lightColor = "#ffffff", darkColor = "#000000", highlightColor = "var(--primaryColor)"}): string => {
    const calcBrightness = (backgroundColor: string): string => {
        if (!backgroundColor) {
            return 0;
        }
        const rgb = backgroundColor.replace(/[^\d,.]/g, "").split(",");
        const r = rgb[0];
        const g = rgb[1];
        const b = rgb[2];
        return Math.round((r * 299 + g * 587 + b * 114) / 1000);
    };
    const colorFlippingBrightness = 126;

    const dispatch = useDispatch();
    useEffect(() => {
        const node = document.querySelector(selector);
        if (node) {
            const computedStyles = window.getComputedStyle(node);
            const brightness = calcBrightness(computedStyles.backgroundColor);
            const isVeryBright = brightness > colorFlippingBrightness;
            dispatch(contrastColorCalculated({
                contrastColor: !isVeryBright ? lightColor : darkColor,
                highlightColor: isVeryBright ? darkColor : highlightColor,
            }));
        }
    }, /*eslint-disable-next-line*/
        [dispatch, selector]);

    return null;
};

export default ContrastColorUpdater;