// @flow

import type {Dispatch} from "redux";
import type {ApplicationState, GetState} from "../reducers";
import NotificationService from "../api/NotificationService";
import type {Captcha} from "../models/Captcha";
import type {WishlistSharingRequest} from "../models/WishlistSharing";
import {i18nToggled, languageSet} from "../reducers/i18nReducer";
import {
	bookmarksToggled,
	wishlistAdded,
	wishListCurrentRemoved,
	wishlistFailureShared,
	wishlistRemoved,
	wishlistResetCaptchaShared,
	wishlistSuccessShared
} from "../reducers/bookmarksReducer";

export const toggleBookmarks = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		if (getState().bookmarks.isMenuOpen) {
			dispatch(bookmarksToggled({isMenuOpen: true, isClosing: true}));
			setTimeout(() => {
				dispatch(bookmarksToggled({isMenuOpen: false, isClosing: false}));
			}, 500);
		} else {
			dispatch(bookmarksToggled({isMenuOpen: true, isClosing: false}));
		}
	};
};

export const toggleI18nSelector = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(i18nToggled(!getState().i18n.isMenuOpen));
	};
};

export const setLanguage = language => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(languageSet(language));
	};
};

export const addToWishList = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		return dispatch(wishlistAdded(getState().content.currentProduct));
	};
};

export const removeCurrentFromWishList = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		return dispatch(wishListCurrentRemoved(getState().content.currentProduct));
	};
};

export const removeFromWishList = (id: string) => {
	return (dispatch: Dispatch<any>) => {
		return dispatch(wishlistRemoved(id));
	};
};

export const resetCaptcha = () => {
	return (dispatch: Dispatch<any>) => {
		return dispatch(wishlistResetCaptchaShared());
	};
};

export const submitBookmarks = (name: string, email: string, msg: string, captcha: Captcha) => {
	return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {

		const {bookmarkedProducts} = getState().bookmarks;
		const wishlistSharingRequest: WishlistSharingRequest = {
			mailMyself: true,
			message: msg,
			customer: {
				phone: "",
				name,
				email,
				wayToContact: "mail",
				language: getState().i18n.languageCode || "en",
			},
			products: bookmarkedProducts.map(product => {
				return {
					productNo: product.product.id,
					bookmarkUrl: product.product.name
				};
			}),
		};

		const notificationService = new NotificationService(captcha);
		notificationService.shareWishlist(wishlistSharingRequest)
			.then(() => {
				dispatch(wishlistSuccessShared());
			}).catch((err: string) => {
			console.error(err);
			dispatch(wishlistFailureShared());
		});
	};
};
