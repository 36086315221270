/* eslint-disable */
// @flow
import * as React from "react";
import "./CarSelector.scss";
import type {CarSelectorPreview} from "../reducers/vehicleReducer";
import type {VehicleOption} from "../models/VehicleOption";
import {Trans} from "react-i18next";
import {ModelSelector} from "./ModelSelector";
import type {Vehicle} from "../models/Vehicle";
import ContrastColorUpdater from "./ContrastColorUpdater";

type CarSelectorProps = {
    availableSeries: CarSelectorPreview[],
    availableModels: Vehicle[],
    isMenuOpen: boolean,
    isClosing: boolean,
    toggleVehicleMenu: () => void,
    handleVehicleSelection: VehicleOption => void,
    highlightColor: string,
};

export const CarSelector = (props: CarSelectorProps) => {
    const {isMenuOpen, isClosing} = props;
    const [modelSelectorOpenFor, setModelSelectorOpenFor] = React.useState(null)

    const handleVehicleSelection = (vehicle: VehicleOption) => {
        props.handleVehicleSelection(vehicle);
        setModelSelectorOpenFor(null);
    }

    const handleSeriesSelection = (series: CarSelectorPreview) => {
        if (props.availableModels.filter(model => model.parentKey === series.key).length > 1) {
            return setModelSelectorOpenFor(series)
        }
        return handleVehicleSelection({series: {key: series.key, label: series.title}, model: undefined})
    }

    const renderVehicle = (vehicle: CarSelectorPreview) => {
        return <button key={vehicle.key} onClick={() => handleSeriesSelection(vehicle)}
                       className="carFilterItem">
            <span style={{color: props.highlightColor}}>{vehicle.title}</span>
            <img src={vehicle.imageUrl} alt={vehicle.key}/>
        </button>;
    }

    return <div className={`CarSelector ${isMenuOpen && !isClosing ? "open" : ""} ${isClosing ? "closing" : ""}`}>
        <ContrastColorUpdater/>
        <button type={"button"} className={"close"} onClick={() => props.toggleVehicleMenu()}/>
        <ModelSelector modelOptions={props.availableModels} onClose={() => setModelSelectorOpenFor(null)}
                       onSelect={handleVehicleSelection} series={modelSelectorOpenFor}
                       shouldOpen={!!modelSelectorOpenFor}/>
        <div className="carBox" data-testid={"carBox"}>
            <div>
                {props.availableSeries.map(vehicle => renderVehicle(vehicle))}
                <button onClick={() => handleVehicleSelection({series: null, model: null})}
                        className="carFilterItem" data-testid={"carItem"}>
                    <span><Trans i18nKey="carSelector.allSeries"/></span>
                </button>
            </div>
        </div>
    </div>;
};
