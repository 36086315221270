// @flow
import configIds from "./configIdMapping";
import RootNodeProvider from "./RootNodeProvider";

export class ConfigIdMapper {
	initialize() {
		const urlParams = new URLSearchParams(window.location.search);
		const {configId, dealer, garage} = Object.fromEntries(urlParams);

		const currentConfigIdType = localStorage.getItem("configIdType");
		const garageIdFromStorage = currentConfigIdType === "garageid"
			? localStorage.getItem("configId")
			: null;

		if (dealer) return this.initializeByDealerId(dealer);
		if (garage) return this.initializeByGarageId(garage);
		if (configId) return this.initializeByOldId(configId);
		if (garageIdFromStorage) return this.initializeByGarageId(garageIdFromStorage);
		return this.initializeFromLocalStorage();
	}

	initializeByOldId(configId) {
		const mapping = configIds[configId];
		if (!mapping) return this.initializeFromLocalStorage();
		if (mapping.configIdType === "garageid") return this.initializeByGarageId(mapping.configId);
		this.setInstance(mapping.configId, mapping.configIdType);
	}

	initializeByDealerId(dealerId) {
		this.setInstance(dealerId, "dealerid");
	}

	initializeByGarageId(garageId) {
		RootNodeProvider.getPosRootNode().removeAttribute("data-dealerid");
		this.setInstance(garageId, "garageid");
	}

	initializeFromLocalStorage() {
		const configId = localStorage.getItem("configId");
		const configIdType = localStorage.getItem("configIdType");
		this.setInstance(configId, configIdType);
	}

	setInstance(configId, configIdType) {
		RootNodeProvider.getPosRootNode().dataset[configIdType] = configId;
		localStorage.setItem("configId", configId);
		localStorage.setItem("configIdType", configIdType);
	}
}
