// @flow
/*eslint-disable*/
import React, { useEffect } from "react";
import { Redirect, Router } from "react-router-dom";
import "./App.scss";
import { Loading } from "./Loading";
import ProductsPageContainer from "../container/CategoryProductsPageContainer";
import CategoryPageContainer from "../container/CategoryPageContainer";
import ProductDetailPageContainer from "../container/ProductDetailPageContainer";
import { customHistory } from "../helper/customHistory";
import { ThemeProvider } from "../themes/ThemeProvider";
import Screensaver from "./Screensaver";
import BrandInstanceContainer from "../container/BrandInstanceContainer";
import StartPageContainer from "../container/StartPageContainer";
import { CompatRouter, Route, Routes, Navigate } from "react-router-dom-v5-compat";
import withLayout from "../helper/withLayout";
import ProductsSearchPageContainer from "../container/ProductsSearchPageContainer";

type AppProps = {
    getConfig: () => void,
    dealerId: string,
    garageId: string;
    configLoaded: string,
    theme: string,
    languageCode: string,
    getBrands: ?string => void,
    setBrand: ?string => void,
    autoSelectInstance: string,
}

export const BrandContext = React.createContext<?string>(null);

const App = (props: AppProps) => {
    const {garageId, getBrands, dealerId, languageCode, getConfig} = props;

    useEffect(() => {
        getConfig();

        if (garageId) {
            getBrands(garageId);
        }
    }, [garageId, dealerId, languageCode, getConfig, getBrands]);

    const themeId = (props.theme || "").replace(" ", "_");

    if (!props.configLoaded || !props.theme) return <Loading/>;

    if (!props.dealerId) {
        return (<div className={"app"} id={themeId}>
            <BrandContext.Provider value={props.theme}>
                <ThemeProvider theme={props.theme}>
                    <Router history={customHistory}>
                        <CompatRouter>
                            <Routes>
                                <Route path={"*"} element={<Navigate replace to={""}/>}/>
                                <Route path="" element={<BrandInstanceContainer/>}/>
                            </Routes>
                        </CompatRouter>
                    </Router>
                </ThemeProvider>
            </BrandContext.Provider>
        </div>);
    }
    return <div className={"app"} id={themeId}>
        <div id="colorPicker"></div>
        <BrandContext.Provider value={props.theme}>
            <ThemeProvider theme={props.theme}>
                <Router history={customHistory}>
                    <CompatRouter>
                        <Routes>
                            <Route path="/start" element={<Screensaver/>}/>
                            <Route path="/product" element={withLayout(ProductsPageContainer)}/>
                            <Route path="/search" element={withLayout(ProductsSearchPageContainer)}/>
                            <Route path="/productDetail" element={withLayout(ProductDetailPageContainer)}/>
                            <Route path="/category" element={withLayout(CategoryPageContainer)}/>
                            <Route exact path="" element={withLayout(StartPageContainer)}/>
                        </Routes>
                    </CompatRouter>
                </Router>
            </ThemeProvider>
        </BrandContext.Provider>
    </div>;
};

export default App;
