// @flow
import ReactPiwik from "react-piwik";
import BaseTracker from "./BaseTracker";
import type {Product} from "../../models/Product";

export default class PiwikTracker extends BaseTracker {
	constructor(trackingId: string, trackingHostUrl: string, withEcommerce: boolean = false) {
		super(trackingId, withEcommerce);
		this.tracker = new ReactPiwik({
			siteId: this.trackingId,
			jsFilename: this.trackingId + ".js",
			url: trackingHostUrl
		});
	}

	handleOrderEvent(order: any): void {
		ReactPiwik.push(["trackEcommerceOrder", order.order.order.order.orderNo.index, order.totalBillingAmount.cost]);
	}

	handleAddItemEvent(cat: string, label: string, product: Product): void {
		ReactPiwik.push(["addEcommerceItem", product.articleNumber, product.name, null, product.price.value, 1]);
	}

	handleRemoveItemEvent(cat: string, label: string, product: Product): void {
		ReactPiwik.push(["removeEcommerceItem", product.articleNumber]);
	};

	handleClearCartEvent(): void {
		ReactPiwik.push(["clearEcommerceCart"]);
	}

	handleInsertToWatchlistEvent(event: {}, product: Product) {
		// do nothing
	}
}
