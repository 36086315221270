// @flow
import type { VehicleOption } from "../models/VehicleOption";
import type { Category } from "../models/Category";
import {createSlice} from "@reduxjs/toolkit";
import { categorySuccessGotten, productsCategorySuccessGotten, productsSearchSuccessGotten } from "./contentReducer";
import { moduleReset } from "./configReducer";

export type FilterStats = {
  categories: Category[],
  priceRange: {
    from: number,
    to: number
  },
  vehicleBrands: any,
  vehicleEngines: any,
  vehicleModels: any,
  vehicleSeries: any,
}

export type FilterReducerState = {
  vehicleOption: VehicleOption,
  isSearchBarOpen: boolean,
  fuzzy: string,
  categories: Category[],
  amount: number,
}

export const filterReducerInitialState: FilterReducerState = {
  vehicleOption: {
    series: null,
    model: null
  },
  isSearchBarOpen: false,
  fuzzy: "",
  categories: [],
  amount: 0
};

const filterSlice = createSlice({
  name: "filter",
  initialState: filterReducerInitialState,
  reducers: {
    searchBarToggled(state, action) {
      state.isSearchBarOpen = action.payload;
    },
    searchPhraseSet(state, action) {
      state.fuzzy = action.payload;
      state.isSearchBarOpen = false;
    },
    vehicleSet(state, action) {
      state.vehicleOption = action.payload;
    },
    vehicleReset(state, action) {
      state.vehicleOption = {};
    }
  },
  extraReducers: builder => {
    builder
      .addCase(productsCategorySuccessGotten, (state, action) => {
        state.categories = action.payload.categories;
        state.amount = action.payload.totalItems;
        state.fuzzy = "";
      })
      .addCase(categorySuccessGotten, (state, action) => {
        state.categories = action.payload.filterStats.categories;
        state.fuzzy = "";
      })
      .addCase(productsSearchSuccessGotten, (state, action) => {
        state.fuzzy = action.payload.fuzzy;
        state.amount = action.payload.totalItems;
      })
      .addCase(moduleReset, () => {
        return filterReducerInitialState;
      });
  }

});

export const { searchBarToggled, searchPhraseSet, vehicleSet, vehicleReset } = filterSlice.actions;

export default filterSlice.reducer;
