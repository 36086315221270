// @flow
import type {BookmarksReducerState} from "./bookmarksReducer";
import bookmarks from "./bookmarksReducer";
import type {ConfigReducerState} from "./configReducer";
import config from "./configReducer";
import type {ContentReducerState} from "./contentReducer";
import content from "./contentReducer";
import type {FilterReducerState} from "./filterReducer";
import filter from "./filterReducer";
import type {VehicleReducerState} from "./vehicleReducer";
import vehicles from "./vehicleReducer";
import type {ScreenSaverReducerState} from "./screensaverReducer";
import screensaver from "./screensaverReducer";
import type {I18nReducerState} from "./i18nReducer";
import i18n from "./i18nReducer";

export type ApplicationState = {
	bookmarks: BookmarksReducerState,
	config: ConfigReducerState,
	content: ContentReducerState,
	filter: FilterReducerState,
	vehicles: VehicleReducerState,
	screensaver: ScreenSaverReducerState,
	i18n: I18nReducerState,
}

export type GetState = () => ApplicationState;

export const  reducer: ApplicationState = {
	bookmarks,
	config,
	content,
	filter,
	i18n,
	vehicles,
	screensaver,
};
