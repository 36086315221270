// @flow

import type {GetState} from "../reducers";
import type {Dispatch} from "redux";
import {searchBarToggled, searchPhraseSet, vehicleReset, vehicleSet} from "../reducers/filterReducer";

export const toggleSearchBar = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(searchBarToggled(!getState().filter.isSearchBarOpen));
	};
};

export const setVehicle = (
	series: { key: string, label: string },
	model: { key: string, label: string }
) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(vehicleSet({series, model}));
	};
};

export const setSearchPhrase = (searchPhrase: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(searchPhraseSet(searchPhrase));
	};
};

export const resetFilter = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(vehicleReset());
	};
};
