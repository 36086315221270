import BrandInstancePage from "../components/BrandInstancePage";
import {connect} from "react-redux";
import {getBrandInstances, setBrandInstance} from "../actions/brand";
import {withTranslation} from "react-i18next";
import {resetFilter} from "../actions/filter";

function mapStateToProps(state) {
	const brandInstances = state.config.brandInstances;
	const shouldRender = brandInstances && brandInstances.length > 0 && brandInstances.length !== 1;

	return {
		brandInstances: brandInstances,
		shouldRender: shouldRender,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		getBrands: configId => dispatch(getBrandInstances(configId)),
		selectBrand: brand => {
			dispatch(resetFilter());
			dispatch(setBrandInstance(brand));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrandInstancePage));
