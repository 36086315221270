// @flow
import React from "react";
import "./SearchBar.scss";
import SearchIcon from "./icons/SearchIcon";
import {useNavigate} from "react-router-dom-v5-compat";

type SearchBarProps = {
	isOpen: boolean,
	handleSubmit: string => void,
	contrastColor: string,
	selectedCategoryKey: string,
	t: string => string,
}

const SearchBar = (props: SearchBarProps) => {
	const [searchString, setSearchString] = React.useState("");
	const navigate = useNavigate();
	const handleSearchPhraseSubmit = e => {
		e.preventDefault();
		props.handleSubmit(searchString);
		navigate(`/search?fuzzy=${searchString}${props.selectedCategoryKey ? `&category=${props.selectedCategoryKey}` : ""}`);
		setSearchString("");
	};

	return props.isOpen
		? <form onSubmit={e => handleSearchPhraseSubmit(e)}
				className="SearchBar"
		>
			<input
				onChange={event => setSearchString(event.target.value)}
				placeholder={props.t("searchBar")}
				value={searchString}
				type="search"
				autoFocus={true}
				required={true}
			/>
			<button type={"submit"}>
				<SearchIcon fill={props.contrastColor}/>
			</button>
		</form>
		: null;
};

export default SearchBar;
