/* eslint-disable no-throw-literal,no-mixed-spaces-and-tabs */
// @flow

import type {Product} from "../../models/Product";
import type {Tracking} from "./models/Tracking";

export default class BaseTracker implements Tracking {
    useEcommerce: boolean;
    trackingId: string;
    eventPrefix: string;

    constructor(trackingId: string, withEcommerce: boolean, eventPrefix = "POS_") {
    	this.useEcommerce = withEcommerce;
    	this.trackingId = trackingId;
    	this.eventPrefix = eventPrefix;
    }

    setEcommerce = value => {
    	this.useEcommerce = value;
    };

    handleGenericEvent(cat: string, action: string, label: any): void {
    	throw ("not implemented");
    };

    handleOrderEvent(order: any): void {
    	throw ("not implemented");
    };

    handleAddItemEvent(cat: string, action: string, product: Product): void {
    	throw ("not implemented");
    };

    handleInsertToWatchlistEvent(event: {}, product: Product): void {
        throw ("not implemented");
    }

    handleRemoveItemEvent(cat: string, action: string, product: Product): void {
    	throw ("not implemented");
    };

    handleClearCartEvent(): void {
    	throw ("not implemented");
    };

    logEvent(cat: string, action: string, label: any): void {
    	this.handleGenericEvent(this.eventPrefix + cat, action, label);
    };

    logOrder(order: any): void {
    	if (this.useEcommerce) this.handleOrderEvent(order);
    };

    addItem(cat: string, label: string, product: Product): void {
    	if (this.useEcommerce) this.handleAddItemEvent(this.eventPrefix + cat, label, product);
    };

    addToWishlist(event: {}, product: Product): void {
        this.handleInsertToWatchlistEvent(event, product);
    };

    removeItem(cat: string, label: string, product: Product): void {
    	if (this.useEcommerce) this.handleRemoveItemEvent(this.eventPrefix + cat, label, product);
    }

    clearCart(): void {
    	if (this.useEcommerce) this.handleClearCartEvent();
    }
}
