// @flow
import {createSlice} from "@reduxjs/toolkit";
import {configSuccessGotten, moduleReset} from "./configReducer";

export type I18nReducerState = {
	isMenuOpen: boolean,
	availableLanguages: string[],
	languageCode: string,
}

export const i18nReducerInitialState: I18nReducerState = {
	isMenuOpen: false,
	availableLanguages: [],
	languageCode: "",
};

const i18nSlice = createSlice({
	name: "i18",
	initialState: i18nReducerInitialState,
	reducers: {
		languageSet(state, action) {
			state.languageCode = action.payload;
			state.isMenuOpen = false;
		},
		i18nToggled(state, action) {
			state.isMenuOpen = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(configSuccessGotten, (state, action) => {
				state.availableLanguages = action.payload.availableLanguages;
				state.languageCode = state.languageCode || action.payload.availableLanguages[0];
			})
			.addCase(moduleReset, () => {
				return i18nReducerInitialState;
			});
	}
});

export const {languageSet, i18nToggled} = i18nSlice.actions;

export default i18nSlice.reducer;
