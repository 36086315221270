// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import Events from "../helper/tracker/Events";

export type StatisticApiOptions = {
	language: ?string,
}

export const shouldTrackOrigin = (): boolean => {
	if (window.slDataLayer && window.slDataLayer.loaded) {
		return false;
	}
	window.slDataLayer = {...(window.slDataLayer || {}), ...{loaded: true}};
	return true;
};

export default class StatisticApi {
	api: Axios;

	constructor(configId: string, {
		language = navigator.language || "en-GB",
	}: StatisticApiOptions = {}) {
		const headers = {
			"X-ModuleType": "POS",
			"Accept-Language": language + ", *;q0.5",
			"X-ConfigId": configId,
		};
		this.api = axios.create({
			baseURL: Configuration.value("apiBaseUrl"),
			headers
		});
	}

	trackModuleLoad = (): void => {
		if (shouldTrackOrigin()) {
			this.trackEvent(Events.ACTIONS.SHOP.LOADED);
		}
	};

	trackProductShared = (productNo: string): void => {
		this.trackEvent(Events.ACTIONS.PRODUCT.PRODUCT_SHARED, productNo);
	};

	trackAddedToWatchlist = (productNo: string): void => {
		this.trackEvent(Events.ACTIONS.BOOKMARKS.ADD_PRODUCT, productNo);
	};

	trackProductQuestion = (productNo: string): void => {
		this.trackEvent(Events.ACTIONS.PRODUCT.PRODUCT_QUESTION, productNo);
	};

	trackProductAddedToCart = (productNo: string): void => {
		this.trackEvent(Events.ACTIONS.CART.ADD_PRODUCT, productNo);
	};

	trackEvent = (eventType: string = "", productNo: string = ""): void => {
		this.api.post("/v2/loading/completed",
			{},
			{
				headers: {
					"X-Event-Type": eventType,
					"X-Product-No": productNo.trim()
				}
			}).catch(() => {
		});
	};

}
