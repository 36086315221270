// @flow
import RootNodeProvider from "../helper/RootNodeProvider";
import {ConfigIdMapper} from "../helper/ConfigIdMapper";
import type {GarageDealership} from "../models/GarageDealership";
import {createSlice} from "@reduxjs/toolkit";

export type TrackingProperties = {
	garageNo: string,
	dealerNo: string,
	dealerName: string,
	trackerLanguage: string,
	analyticsTracker: [],
}

export type ConfigReducerState = {
	dealerId: string,
	garageId: string,
	theme: string,
	configLoaded: boolean,
	showIdleModal: boolean,
	bookmarksMail: string,
	trackingProperties: TrackingProperties,
	brandInstances: GarageDealership[],
	contrastColor: string,
	highlightColor: string,
	noKeyboard: boolean,
	manufacturerLogoUrl: string
}

const getInitialState = () => {
	new ConfigIdMapper().initialize();

	const noKeyboardSearchParam = (new URLSearchParams(window.location.search)).get("noKeyboard");
	const {dealerid, garageid, noKeyboard} = RootNodeProvider.getPosRootNode().dataset;

	return {
		dealerId: dealerid || null,
		garageId: garageid || null,
		theme: null,
		configLoaded: false,
		showIdleModal: false,
		bookmarksMail: "",
		trackingProperties: {},
		brandInstances: [],
		contrastColor: "#ffffff",
		highlightColor: "#000000",
		noKeyboard: noKeyboard !== undefined || noKeyboardSearchParam !== null,
		manufacturerLogoUrl: ""
	};
};

const initialState: ConfigReducerState = getInitialState();

const configSlice = createSlice({
	name: "config",
	initialState,
	reducers: {
		moduleReset() {
			window.slDataLayer = {...(window.slDataLayer || {}), ...{loaded: false}};
			return initialState;
		},
		configSuccessGotten(state, action) {
			state.configLoaded = true;
			state.languageCode = action.payload.configurationDetails.languageCode;
			state.trackingProperties = action.payload.configurationDetails.trackingProperties;
			state.theme = action.payload.configurationDetails.theme;
			state.bookmarksMail = action.payload.dealerDetails.email;
			const selectedGarageDealership = action.payload.garageDealerships.find(garageDealerships => garageDealerships.manufacturerName === state.theme);
			state.manufacturerLogoUrl = selectedGarageDealership ? selectedGarageDealership.manufacturerLogoUrl : "";
		},
		idleModalToggled(state, action) {
			state.showIdleModal = action.payload;
		},
		brandInstanceGotten(state, action) {
			state.brandInstances = action.payload;
		},
		brandInstanceReset(state) {
			state.dealerId = null;
		},
		brandInstanceSet(state, action) {
			state.dealerId = action.payload;
		},
		contrastColorCalculated(state, action) {
			state.contrastColor = action.payload.contrastColor;
			state.highlightColor = action.payload.highlightColor;
		}
	}
});

export const {
	configSuccessGotten,
	brandInstanceGotten,
	brandInstanceReset,
	brandInstanceSet,
	contrastColorCalculated,
	idleModalToggled,
	moduleReset
} = configSlice.actions;

export default configSlice.reducer;
