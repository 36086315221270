// @flow

import type {Product} from "../models/Product";
import {createSlice} from "@reduxjs/toolkit";
import {moduleReset} from "./configReducer";

export type BookmarksReducerState = {
	isMenuOpen: boolean,
	isClosing: boolean,
	bookmarkedProducts: Product[],
	bookmarkError: boolean,
	bookmarkSuccess: boolean,
}

export const bookmarksReducerInitialState: BookmarksReducerState = {
	isMenuOpen: false,
	isClosing: false,
	bookmarkedProducts: [],
	bookmarkError: false,
	bookmarkSuccess: false,
};

const bookMarksSlice = createSlice({
	name: "bookMarks",
	initialState: bookmarksReducerInitialState,
	reducers: {
		wishlistSuccessShared(state) {
			state.bookmarkedProducts = [];
			state.bookmarkSuccess = true;
		},
		wishlistFailureShared(state) {
			state.bookmarkError = true;
		},
		wishlistResetCaptchaShared(state) {
			state.bookmarkError = false;
			state.bookmarkSuccess = false;
		},
		bookmarksToggled(state, action) {
			state.isMenuOpen = action.payload.isMenuOpen;
			state.isClosing = action.payload.isClosing;
		},
		wishlistAdded(state, action) {
			state.bookmarkedProducts = [...state.bookmarkedProducts, action.payload];
		},
		wishListCurrentRemoved(state, action) {
			state.bookmarkedProducts = state.bookmarkedProducts.filter(bookmark => bookmark.product.id !== action.payload.product.id);
		},
		wishlistRemoved(state, action) {
			state.bookmarkedProducts = state.bookmarkedProducts.filter(bookmark => bookmark.product.id !== action.payload.product.id);
		},
		wishlistCleared(state) {
			state.bookmarkedProducts = [];
		}
	},
	extraReducers: builder => {
		builder
			.addCase(moduleReset, () => {
				return bookmarksReducerInitialState;
			});
	}
});

export const {
	wishlistAdded,
	wishlistCleared,
	wishlistRemoved,
	bookmarksToggled,
	wishlistFailureShared,
	wishlistSuccessShared,
	wishlistResetCaptchaShared,
	wishListCurrentRemoved
} = bookMarksSlice.actions;

export default bookMarksSlice.reducer;