// @flow
import * as React from "react";
import Spinner from "react-spinkit";
import "./Loading.scss";

export const Loading = () => {
	return <div className={"Loading"} data-testid={"loader"}>
		<Spinner name="circle"/>
	</div>;
};
