// @flow

class RootNodeProviderInstance {
	getPosRootNode = () => {
		const root = document.getElementById("itt-sl-pos");
		if (!root) {
			if (process.env.NODE_ENV === "test") {
				const testRoot = document.querySelector("body");
				testRoot.dataset["dealerid"] = "ford_TEST";
				testRoot.id = "itt-sl-pos";
				return testRoot;
			}
			console.error("root node should look like : <div data-dealerid=\"your_dealerNo\" id=\"itt-sl-pos\">");
			return null;
		}
		return root;
	};
}

const RootNodeProvider = new RootNodeProviderInstance();
export default RootNodeProvider;
