// @flow

import type {ApplicationState} from "../reducers";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {enableIdleModal} from "../actions/config";
import {Timeout} from "../components/Timeout";

function mapStateToProps(state: ApplicationState) {
	return {
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		showIdleModal: () => dispatch(enableIdleModal()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Timeout));
