// @flow
import type {ApplicationState} from "../reducers";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import App from "../components/App";
import {getConfig} from "../actions/config";
import {getBrandInstances, setBrandInstance} from "../actions/brand";

function mapStateToProps(state: ApplicationState) {
	const brandInstances = state.config.brandInstances;
	const autoSelectInstance = brandInstances.length === 1 ? brandInstances[0].dealerId : null;

	return {
		dealerId: state.config.dealerId,
		garageId: state.config.garageId,
		configLoaded: state.config.configLoaded,
		theme: state.config.theme,
		languageCode: state.i18n.languageCode,
		autoSelectInstance: autoSelectInstance,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		getConfig: () => dispatch(getConfig()),
		getBrands: configId => dispatch(getBrandInstances(configId)),
		setBrand: brand => {
			dispatch(setBrandInstance(brand));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
