// @flow
import * as React from "react";
import type {Price} from "../models/Product";
import {Currency} from "./Currency";
import {Trans} from "react-i18next";

type Props = {
    price: Price,
    rrp: ?Price,
    withMontage: boolean,
};
export const PriceBox = (props: Props) => {
    return <div className={"priceBox"}>
        <span className="retail_price_label">{!!props.rrp && <Currency price={props.rrp}/>}</span>
        {!!props.price && (
            <React.Fragment>
				<span className="price_label">
					<Currency price={props.price}/>
				</span>
                {!!props.unitPrice && (
                    <small className="unit-price">
                        (<Currency price={props.unitPrice.pricePerUnit}/> /{" "}
                        {props.unitPrice.measuringUnit === "sqm" ? "m²" : props.unitPrice.measuringUnit === "cbm" ? "m³" : props.unitPrice.measuringUnit})
                    </small>
                )}
            </React.Fragment>
        )}
        <span className={"vat"}><Trans i18nKey={props.withMontage ? "priceBox.withVatAndMontage" : "priceBox.withVat"}/></span>
    </div>;
};
