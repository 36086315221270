/* eslint-disable jsx-a11y/anchor-is-valid,react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./I18nSelector.scss";

type Props = {
	onSelect: () => void,
	onClose: () => void,
	shouldOpen: boolean,
	availableLanguages: string[],
};

export const I18nSelector = (props: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const deps = props.shouldOpen !== isOpen;
	React.useEffect(() => {
		setTimeout(() => {
			setIsOpen(props.shouldOpen);
		}, 500);
	}, [deps, props.availableLanguages]);
	const renderLanguage = (language: string) => {
		const languageNames = new Intl.DisplayNames([language], {
			type: "language",
		});
		return <div key={language}>
			<a onClick={() => props.onSelect(language)} key={language} data-testid={"language"}>
				<span>
					{languageNames.of(language)}
				</span>
			</a>
		</div>;
	};

	return (
		<aside className={`I18nSelector ${(props.shouldOpen === isOpen) && isOpen === true ? "open" : ""}`}
			   onClick={() => props.onClose()} data-testid={"languageMenu"}>
			{props.availableLanguages.length && <div onClick={e => e.stopPropagation()}>
				<div>
					{props.availableLanguages.map(language => renderLanguage(language))}
				</div>
			</div>}
		</aside>
	);
};
