// @flow
import RootNodeProvider from "../helper/RootNodeProvider";
import {createSlice} from "@reduxjs/toolkit";
import {configSuccessGotten, moduleReset} from "./configReducer";

export type ScreenSaverReducerState = {
	video: ?string,
	videoUrl: ?string,
	imageUrl: ?string,
	disableScreensaver: boolean,
}

const getInitialState = () => {
	const noVideoSearchParam = (new URLSearchParams(window.location.search)).get("noVideo");
	const {videourl, imageurl, noVideo} = RootNodeProvider.getPosRootNode().dataset;

	return {
		videoUrl: videourl,
		imageUrl: imageurl,
		video: null,
		disableScreensaver: noVideo !== undefined || noVideoSearchParam !== null,
	};
};

export const initialState: ScreenSaverReducerState = getInitialState();

const screenSaverSlice = createSlice({
	name: "screenSaver",
	initialState,
	reducers: {
		videoLoaded(state, action) {
			state.video = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(configSuccessGotten, (state, action) => {
				if (action.payload.configurationDetails.videoUrl !== state.videoUrl) {
					state.videoUrl = action.payload.configurationDetails.videoUrl;
					state.video = null;
				}
			})
			.addCase(moduleReset, () => {
				return initialState;
			});
	}
});

export const {videoLoaded} = screenSaverSlice.actions;

export default screenSaverSlice.reducer;
