// @flow
import * as React from "react";
import type {Product} from "../models/Product";
import "./ProductTile.scss";
import {Currency} from "./Currency";
import {Link} from "react-router-dom";

type Props = {
    productItem: Product,
    isOnWishList: boolean,
    highlightColor: string,
    disableWishlist: boolean,
};
export const ProductTile = (props: Props) => {
    const isShortened = props.productItem.name.length > 42;
    const productTitle = isShortened ? props.productItem.name.substring(0, 42) + "..." : props.productItem.name.substring(0, 44);
    const productImage = props.productItem.galleryImage || {};
    const isDiscounted = !!props.productItem.recommendedRetailPrice;
    const withMounting = props.productItem.distributionChannels.mounting;

    const renderBadge = () => {
        if (withMounting) {
            return <div
                style={{
                    backgroundColor: props.highlightColor,
                    color: props.highlightColor === "#ffffff" ? "#000000" : "#ffffff",
                }}
                className="badge mounting" data-testid={"productBadge"}>
                inkl. Montage</div>;
        }
        if (isDiscounted) {
            return <div style={{
                backgroundColor: props.highlightColor,
                color: props.highlightColor === "#ffffff" ? "#000000" : "#ffffff",
            }} className="badge sell" data-testid={"productBadge"}>Angebot</div>;
        }
        return null;
    };

    const renderWishlistIndicator = (isActive: boolean) => {
        if (props.disableWishlist) {
            return null;
        }

        return <div className="wishlist" data-testid={"productWishlist"}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill={isActive ? "var(--primaryColor)" : "none"}
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_42_35)">
                    <path
                        d="M19.5001 12.5721L12.0001 20.0001L4.50006 12.5721C4.00536 12.0907 3.6157 11.5121 3.3556 10.8727C3.09551 10.2333 2.97062 9.54701 2.98879 8.85699C3.00697 8.16697 3.16782 7.48819 3.46121 6.86339C3.75461 6.23859 4.17419 5.68131 4.69354 5.22663C5.21289 4.77196 5.82076 4.42974 6.47887 4.22154C7.13697 4.01333 7.83106 3.94364 8.51743 4.01686C9.20379 4.09007 9.86756 4.30461 10.4669 4.64696C11.0663 4.98931 11.5883 5.45205 12.0001 6.00605C12.4136 5.45608 12.9362 4.99738 13.5352 4.65866C14.1341 4.31994 14.7966 4.1085 15.481 4.03757C16.1654 3.96665 16.8571 4.03775 17.5128 4.24645C18.1685 4.45514 18.7741 4.79693 19.2916 5.25042C19.8091 5.70391 20.2275 6.25934 20.5205 6.88195C20.8135 7.50456 20.9748 8.18094 20.9944 8.86876C21.0139 9.55659 20.8913 10.241 20.6342 10.8793C20.3771 11.5176 19.991 12.0959 19.5001 12.5781"
                        stroke="#2E2E2E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_42_35">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>;
    };

    return <Link to={`/productDetail/?productId=${props.productItem.id}`} key={props.productItem.id}
                 className={"product"} data-testid={"productLink"}>
		<span className="imageContainer" data-testid={"productImage"}>
            {renderBadge()}
            <img src={productImage.previewImage ? productImage.previewImage.attachmentUrl : productImage.attachmentUrl}
                 alt={productTitle}
            />
		</span>
        <div className="productDetails">
            <div className="productTitle" data-testid={"productTitle"}>{productTitle}</div>
            <div className="series" data-testid={"productSeries"}>
                {props.productItem.carlines.map((fitment, index) => {
                    const key = props.productItem.id + fitment;
                    if (index === 0) {
                        return <React.Fragment key={key}>{fitment}</React.Fragment>;
                    }
                    if (index <= 1) {
                        return <React.Fragment key={key}>{`, ${fitment}`}</React.Fragment>;
                    }
                    if (index === 2) {
                        return <React.Fragment key={key}> und weitere...</React.Fragment>;
                    }
                    return null;
                })}
            </div>
            <div className="price" data-testid={"productPrices"}>
			<span className="retail_price_label" data-testid={"productRetailPrice"}>
				{!!props.productItem.recommendedRetailPrice &&
                    <Currency price={props.productItem.recommendedRetailPrice}/>}
			</span>
                {props.productItem.price && (
                    <div className="price_container">
                        <div className="price_label" style={{color: props.highlightColor}} data-testid={"productPrice"}>
                            <Currency price={props.productItem.price}/>
                        </div>
                        {props.productItem.unitPrice && (
                            <div className="unit-price">
                                <small>
                                    (<Currency
                                    price={props.productItem.unitPrice.pricePerUnit}/>/ {props.productItem.unitPrice.measuringUnit === "sqm" ? "m²" : props.productItem.unitPrice.measuringUnit === "cbm" ? "m³" : props.productItem.unitPrice.measuringUnit})
                                </small>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {renderWishlistIndicator(props.isOnWishList)}
        </div>
    </Link>;
};
