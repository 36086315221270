// @flow

import type {ApplicationState} from "../reducers";
import type {Dispatch} from "redux";
import {connect} from "react-redux";
import {Bookmarks} from "../components/Bookmarks";
import {removeFromWishList, resetCaptcha, submitBookmarks, toggleBookmarks} from "../actions/bookmarks";
import type {Captcha} from "../models/Captcha";

function mapStateToProps(state: ApplicationState, ownProps: CarSelectorContainerProps) {
	return {
		isMenuOpen: state.bookmarks.isMenuOpen,
		isClosing: state.bookmarks.isClosing,
		bookmarks: state.bookmarks.bookmarkedProducts,
		bookmarksMail: state.config.bookmarksMail,
		bookmarkError: state.bookmarks.bookmarkError,
		bookmarkSuccess: state.bookmarks.bookmarkSuccess,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CarSelectorContainerProps) {
	return {
		toggleBookmarks: () => dispatch(toggleBookmarks()),
		submitBookmarks: (name: string, email: string, msg: string, captcha: Captcha) => dispatch(submitBookmarks(name, email, msg, captcha)),
		removeBookmark: (bookmark: Product) => dispatch(removeFromWishList(bookmark)),
		resetBookmarks: () => dispatch(resetCaptcha()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);
