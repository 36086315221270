/* eslint-disable */
// @flow
import * as React from "react";
import "./IdleModal.scss";
import Countdown, {zeroPad} from "react-countdown";
import {Trans} from "react-i18next";

type CarSelectorProps = {
	shouldOpen: boolean,
	hideModal: () => void,
	performReset: () => void
};

export const IdleModal = (props: CarSelectorProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const deps = props.shouldOpen !== isOpen;

	React.useEffect(() => {
		if (isOpen) {
			document.querySelector("html").classList.add("filterOpen")
		} else {
			document.querySelector("html").classList.remove("filterOpen")
		}
	}, [isOpen])

	const renderer = ({minutes, seconds, completed}: any) => {
		if (completed) {
			return null;
		}
		return <div className={"counter"}>
			<h2><Trans i18nKey="idleModal.title"/></h2>
			<p><Trans i18nKey="idleModal.subTitle"/></p>
			<p>{zeroPad(minutes)}:{zeroPad(seconds)}</p>
			<div>
				<button className={"back"} onClick={() => props.hideModal()}><Trans i18nKey="idleModal.buttonBackLabel"/></button>
			</div>
		</div>;
	};

	React.useEffect(() => {
		setTimeout(() => {
			setIsOpen(props.shouldOpen);
		}, 500);
	}, [deps]);

	if (isOpen) {
		return <div className={`IdleModal ${(props.shouldOpen === isOpen) ? "open" : ""}`}>
			<button type={"button"} className={"close"} onClick={() => props.hideModal()}/>
			<Countdown
				date={Date.now() + 1000 * 10}
				intervalDelay={0}
				onComplete={() => props.performReset()}
				renderer={renderer}
			/>
		</div>;
	}
	return null;
};
