// @flow

export class ModuleInitiationExeption extends Error {
	code: string;

	constructor(message: string, code: string) {
		super(message);
		this.code = code;
	}
}
