// @flow
import React, {useEffect} from "react";
import type {Captcha} from "../models/Captcha";
import "./SlCaptcha.scss";
import NotificationService from "../api/NotificationService";
import {withTranslation} from "react-i18next";
import type {translate} from "../i18n/i18n";

export type SlCaptchaProps = {
	setCaptcha: Captcha => void,
	captcha: Captcha,
	error: boolean,
	t: translate,
};

const SlCaptcha = (props: SlCaptchaProps) => {
	const {error, setCaptcha} = props;
	useEffect(() => {
		const api = new NotificationService();
		api.getCaptcha().then(it => setCaptcha(it));
	}, [error, setCaptcha]);

	return props.captcha
		? <div className={"slCaptcha"}>
			<div className={"captchaInput textField"}>
				<input type={"text"} placeholder={props.t("bookmarks.form.enterCaptcha")} required={true}
					   value={props.captcha.captchaSolution.replace(" ", "").toLowerCase()}
					   onChange={e => setCaptcha({...props.captcha, captchaSolution: e.target.value})}/>
			</div>
			<div className={"captchaImage"}>
				<img src={props.captcha.base64} alt={"Captcha"}/>
			</div>
		</div>
		: <div/>;
};

export default withTranslation()(SlCaptcha);
