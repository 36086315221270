// @flow
import * as React from "react";
import "./Filter.scss";
import {Trans} from "react-i18next";
import {useNavigate} from "react-router-dom-v5-compat";

type Props = {
    fuzzy: string,
    resetFuzzy: () => void,
    categoryPath: string[],
    amount: number,
    contrastColor: string,
};

export const Filter = (props: Props) => {
    const navigate = useNavigate();
    const handleResetFuzzy = () => {
        return navigate("/");
    };

    const renderCategory = (category: string, isLast: boolean) => {
        return <div className={`filterPill category ${(isLast ? "" : "isLast")}`} key={category}>
            {category}
        </div>;
    };

    if (props.categoryPath.length || props.fuzzy) {
        return <div className={"Filter"} data-testid={"filterPill"}>
            {props.categoryPath.map((category, index) => renderCategory(category, index < props.categoryPath.length - 1))}
            {props.fuzzy &&
                <button onClick={() => handleResetFuzzy()} className="filterPill fuzzy"
                >
                    <Trans i18nKey="misc.searching"/> {props.fuzzy}
                </button>
            }
            <div className="amount">({props.amount})</div>
        </div>;
    }
    return null;
};
