/* eslint-disable */
// @flow
import * as React from "react";
import {useState} from "react";
import "./Bookmarks.scss";
import {Currency} from "./Currency";
import type {Captcha} from "../models/Captcha";
import type {Product} from "../models/Product";
import {Trans} from 'react-i18next'
import SlCaptcha from "./SlCaptcha";


type BookmarksProps = {
	isMenuOpen: boolean,
	isClosing: boolean,
	toggleBookmarks: () => void,
	bookmarks: Product[],
	submitBookmarks: (string, string, string, Captcha) => void,
	removeBookmark: Product => void,
	bookmarksMail: string,
	bookmarkError: boolean,
	bookmarkSuccess: boolean,
	resetBookmarks: () => void,
};

export const Bookmarks = (props: BookmarksProps) => {
	const {isMenuOpen, isClosing} = props;
	const [isFinished, setIsFinished] = React.useState(false);
	const [customerName, setCustomerName] = React.useState("");
	const [customerEmail, setCustomerEmail] = React.useState("");
	const [isCustomerEmailValid, setIsCustomerEmailValid] = React.useState(true);
	const [customerMsg, setCustomerMsg] = React.useState("");
	const [captcha, setCaptcha] = useState(null);
	const {bookmarkError, bookmarkSuccess, resetBookmarks} = props;

	const handleMailChange = (value) => {
		setIsCustomerEmailValid(value === "" || value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i));
		setCustomerEmail(value)
	}

	React.useEffect(() => {
		if (bookmarkSuccess) {
			resetBookmarks();
		}
	}, [bookmarkSuccess, resetBookmarks])

	React.useEffect(() => {
		if (bookmarkError) {
			resetBookmarks();
		}
	}, [bookmarkError, resetBookmarks])

	const renderBookmark = (bookmark: Product) => {
		return <div className={"benchmark"} key={bookmark.product.id}>
			<div className={"benchmarkContent"}>
				<div className="imageBox">
					<img src={bookmark.product.galleryImage.previewImage ? bookmark.product.galleryImage.previewImage.attachmentUrl : bookmark.product.galleryImage.attachmentUrl} alt={bookmark.product.articleNumber}/>
				</div>
				<div className="textBox" data-testid={"bookmarkItem"}>
					<div className="benchmark-title">{bookmark.product.name}</div>
					<div className="benchmark-price">
						{!!bookmark.product.price && <React.Fragment>
							<Currency price={bookmark.product.price}/>
							{bookmark.product.unitPrice && (
								<div>
									<small className="unit-price">
										({<Currency price={bookmark.product.unitPrice.pricePerUnit}/>}/{bookmark.product.unitPrice.measuringUnit === "sqm" ? "m²" : bookmark.product.unitPrice.measuringUnit === "cbm" ? "m³" :
												bookmark.product.unitPrice.measuringUnit})
									</small>
								</div>
							)}
						</React.Fragment>}
					</div>
					<small><i><Trans i18nKey="bookmarks.withVat"/></i></small>
				</div>
			</div>
			<div className="btnBox">
				<button type={"button"} onClick={() => props.removeBookmark(bookmark)}><Trans
					i18nKey="bookmarks.delete"/></button>
			</div>
		</div>
	}

	const renderFormFinished = () => {
		return <div className="bookmarksWrap">
			<div>
				<Trans i18nKey="bookmarks.form.finishedLabel"/>
			</div>
		</div>
	};

	const renderForm = () => {
		const handleSubmit = e => {
			e.preventDefault();
			props.submitBookmarks(customerName, customerEmail, customerMsg, captcha);
		}

		return <form onSubmit={e => handleSubmit(e)} className={"contact"}>
			<div>
				<div className="textField">
					<input required={true} placeholder={"Name"} id={"name"} value={customerName}
						   onChange={(e) => setCustomerName(e.target.value)} type="text"/>
					<label htmlFor="name"><Trans i18nKey="bookmarks.nameLabel"/></label>
				</div>
				<div className="textField">
					<input required={true} placeholder={"E-Mail"} id={"email"} value={customerEmail}
						   className={isCustomerEmailValid ? "" : "error"}
						   onChange={(e) => handleMailChange(e.target.value)} type="email"/>
					<label htmlFor="email"><Trans i18nKey="bookmarks.mailLabel"/></label>
				</div>
				<div className="textField">
					<input required={true} placeholder={"Nachricht"} id={"msg"} value={customerMsg}
						   onChange={(e) => setCustomerMsg(e.target.value)} type="text"/>
					<label htmlFor="msg"><Trans i18nKey="bookmarks.msgLabel"/></label>
				</div>
				<SlCaptcha
					setCaptcha={setCaptcha}
					captcha={captcha}
					error={props.bookmarkError}
				/>
			</div>
			<div className={"receiverEmail"}>
				<Trans i18nKey="bookmarks.form.sendTo"/> {props.bookmarksMail}
			</div>
			<button disabled={!customerName || !customerEmail || !isCustomerEmailValid || !captcha || !captcha.captchaSolution}
							data-testid={"submitBookmarks"} type={"submit"}>
				<span className="icon send"/>
				<span id={"send-list"}> <Trans i18nKey="bookmarks.form.submitLabel"/></span>
			</button>
		</form>
	}

	const renderContent = () => {
		if (isFinished) {
			return renderFormFinished();
		}

		if (props.bookmarks.length) {
			return <React.Fragment>
				<div className={"bookmarksWrap"}>
					{props.bookmarks.map(bookmark => renderBookmark(bookmark))}
				</div>
				{/*
				<div className={"notice"}>
					<Trans i18nKey="bookmarks.notice"/>
				</div>
				*/}
				{renderForm()}
			</React.Fragment>
		}
		return <div className={"empty"}><Trans i18nKey="bookmarks.empty"/></div>
	}

	return <div className={`Bookmarks ${isMenuOpen && !isClosing ? "open" : ""} ${isClosing ? "closing" : ""}`} data-testid={"bookmarks"}>
		<button type={"button"} className={"close"} onClick={() => props.toggleBookmarks()}/>
		<div>
			<div className="bigStar">
				<div className={"icon star"}/>
				<br/>
			</div>
			<div className={"title"}><Trans i18nKey="bookmarks.title"/></div>
			{renderContent()}
		</div>
	</div>;
};
