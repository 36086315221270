import HeaderContainer from "../container/HeaderContainer";
import React from "react";

const withLayout = Component => {
	return <React.Fragment>
		<HeaderContainer/>
		<Component/>
	</React.Fragment>;
};

export default withLayout;