// @flow
import type {Product} from "../models/Product";
import type {Attachments} from "../models/Attachments";
import type {TireLabel} from "../models/TireLabel";
import type {Property} from "../models/Property";
import {createSlice} from "@reduxjs/toolkit";
import {moduleReset} from "./configReducer";

export type PagedProduct = {
	totalItems: number,
	totalPages: number,
	productItems: Product[],
}

export type ProductDetail = {
	product: Product,
	attachments: Attachments,
	properties: Property[],
	relatedProduct: Product[],
	tireLabel: TireLabel
}

export type ContentReducerState = {
	mainCategories: Category[],
	otherCategories: Category[],
	selectedCategory: ?Category,
	pagedProducts: ?PagedProduct,
	requestQueue: string[],
	currentCategory: ?Category,
	currentProduct: ?ProductDetail,
	currentPage: number,
}

const initialState: ContentReducerState = {
	mainCategories: [],
	otherCategories: [],
	selectedCategory: null,
	pagedProducts: [],
	requestQueue: [],
	currentPage: 0,
	currentProduct: null,
};


const contentSlice = createSlice({
	name: "content",
	initialState,
	reducers: {
		categoryRequestGotten(state, action) {
			state.mainCategories = [];
			state.otherCategories = [];
			state.selectedCategory = null;
			state.requestQueue = [...state.requestQueue, action.type];
		},
		categorySuccessGotten(state, action) {
			state.mainCategories = action.payload.mainCategories;
			state.otherCategories = action.payload.otherCategories;
			state.selectedCategory = action.payload.selectedCategory;
			state.requestQueue = state.requestQueue.filter(el => el !== categoryRequestGotten.type);
		},
		productsCategoryRequestGotten(state, action) {
			if (action.payload === 0) {
				state.currentPage = 0;
				state.requestQueue = [...state.requestQueue, action.type];
				state.pagedProducts = [];
			} else {
				state.currentPage = action.payload;
				state.requestQueue = [...state.requestQueue, action.type];
			}
		},
		productsCategorySuccessGotten(state, action) {
			state.pagedProducts = [...state.pagedProducts, ...action.payload.productItems];
			state.totalItems = action.payload.totalItems;
			state.requestQueue = state.requestQueue.filter(el => el !== productsCategoryRequestGotten.type);
		},
		productsSearchRequestGotten(state, action) {
			if (action.payload === 0) {
				state.currentPage = 0;
				state.requestQueue = [...state.requestQueue, action.type];
				state.pagedProducts = [];
			} else {
				state.currentPage = action.payload;
				state.requestQueue = [...state.requestQueue, action.type];
			}
		},
		productsSearchSuccessGotten(state, action) {
			state.pagedProducts = [...state.pagedProducts, ...action.payload.productItems];
			state.requestQueue = state.requestQueue.filter(el => el !== productsSearchRequestGotten.type);
		},
		productRequestGotten(state) {
			state.currentProduct = null;
		},
		productSuccessGotten(state, action) {
			state.currentProduct = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(moduleReset, () => {
				return initialState;
			});
	}
});

export const {
	categoryRequestGotten,
	categorySuccessGotten,
	productRequestGotten,
	productSuccessGotten,
	productsCategoryRequestGotten,
	productsCategorySuccessGotten,
	productsSearchRequestGotten,
	productsSearchSuccessGotten
} = contentSlice.actions;

export default contentSlice.reducer;
