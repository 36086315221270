// @flow
import {connect} from "react-redux";
import StartPage from "../components/StartPage";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {setSearchPhrase} from "../actions/filter";
import {getCategory} from "../actions/content";
import {setLanguage, toggleI18nSelector} from "../actions/bookmarks";

type StartPageContainerProps = {};

function mapStateToProps(state: ApplicationState, ownProps: StartPageContainerProps) {
	return {
		mainCategories: state.content.mainCategories,
		otherCategories: state.content.otherCategories,
		currentCategory: null,
		vehicleOption: state.filter.vehicleOption,
		isI18nMenuOpen: state.i18n.isMenuOpen,
		availableLanguages: state.i18n.availableLanguages,
		highlightColor: state.config.highlightColor,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: StartPageContainerProps) {
	return {
		toggleI18nSelector: () => dispatch(toggleI18nSelector()),
		setLanguage: language => {
			dispatch(setLanguage(language));
			dispatch(getCategory());
		},
		onLoad: () => {
			dispatch(setSearchPhrase(""));
			dispatch(getCategory());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StartPage));
