// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import ProductsDetailPage from "../components/ProductsDetailPage";
import {getProductDetails} from "../actions/content";
import {addToWishList, removeCurrentFromWishList} from "../actions/bookmarks";
type ProductsDetailPageContainerProps = {};

function mapStateToProps(state: ApplicationState, ownProps: ProductsDetailPageContainerProps) {
	const productId =new URLSearchParams(window.location.search).get("productId");
	const currentProduct = state.content.currentProduct;

	if (currentProduct) {
		return {
			productId,
			currentProduct,
			isOnWishList: !!state.bookmarks.bookmarkedProducts.find(bookmarkedProduct => bookmarkedProduct.product.id === currentProduct.product.id),
			highlightColor: state.config.highlightColor,
			contrastColor: state.config.contrastColor,
			disableWishlist: state.config.noKeyboard,
			wishlist: state.bookmarks.bookmarkedProducts
		};
	}
	return {
		productId,
		currentProduct,
		isOnWishList: false,
		highlightColor: state.config.highlightColor,
		contrastColor: state.config.contrastColor,
		disableWishlist: state.config.noKeyboard,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: ProductsDetailPageContainerProps) {
	return {
		onLoad: productId => dispatch(getProductDetails(productId)),
		cleanup: () => dispatch(getProductDetails("")),
		addToWishList: () => dispatch(addToWishList()),
		removeFromWishList: () => dispatch(removeCurrentFromWishList())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductsDetailPage));
