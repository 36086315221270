/* eslint-disable jsx-a11y/anchor-is-valid,react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./ModelSelector.scss";
import {CarSelectorPreview} from "../reducers/vehicleReducer";
import type {Vehicle} from "../models/Vehicle";
import {Trans} from "react-i18next";
import type {VehicleOption} from "../models/VehicleOption";

type Props = {
	series: CarSelectorPreview,
	modelOptions: Vehicle[],
	onSelect: VehicleOption => void,
	onClose: () => void,
	shouldOpen: boolean
};
export const ModelSelector = (props: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [currentSeries, setCurrentSeries] = React.useState(null);
	const deps = props.shouldOpen !== isOpen;
	React.useEffect(() => {
		setTimeout(() => {
			setIsOpen(props.shouldOpen);
			setCurrentSeries(props.series);
		}, 500);
	}, [deps, props.series]);
	const availableModels = currentSeries ? props.modelOptions.filter(model => model.parentKey === currentSeries.key) : [];
	return (
		<div className={`ModelSelector ${(props.shouldOpen === isOpen) && isOpen === true ? "open" : ""}`}
			 onClick={() => props.onClose()}>
			{currentSeries && <div onClick={e => e.stopPropagation()}>
				<div>
					<div>
						<a onClick={() => props.onSelect({
							series: {key: currentSeries.key, label: currentSeries.title},
							model: undefined
						})}>
							<img src={currentSeries.imageUrl} alt={currentSeries.name}/>
							<span>{currentSeries.title}</span>
						</a>
						<a key={"all"} onClick={() => props.onSelect({
							series: {key: currentSeries.key, label: currentSeries.title},
							model: undefined
						})}><Trans i18nKey="carSelector.all"/></a>
						{availableModels.map(model => <a key={model.key} onClick={() => props.onSelect({
							series: null,
							model: {key: model.key, label: model.title}
						})}>{model.title}</a>)}
					</div>
				</div>
			</div>}
		</div>
	);
};
