// @flow
import type {Vehicle} from "../models/Vehicle";
import {createSlice} from "@reduxjs/toolkit";
import {configSuccessGotten, moduleReset} from "./configReducer";

export type CarSelectorPreview = {
	key: string,
	imageUrl: string,
	name: string,
	title: string,
	categories: [],
};

export type VehicleReducerState = {
	selected: ?Vehicle,
	availableSeries: CarSelectorPreview[],
	availableModels: Vehicle[],
	isMenuOpen: boolean,
	isClosing: boolean,
}

const initialState: VehicleReducerState = {
	selected: null,
	availableSeries: [],
	availableModels: [],
	isMenuOpen: false,
	isClosing: false,
};

const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {
		vehicleMenuToggled(state, action) {
			state.isMenuOpen = action.payload.isMenuOpen;
			state.isClosing = action.payload.isClosing;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(configSuccessGotten, (state, action) => {
				const getVehicleModels = (vehicleSeries: []): [] => {
					let vehicleModels = [];
					for (let series of vehicleSeries) {
						for (let category of series.categories) {
							vehicleModels.push(category);
						}
					}
					return vehicleModels;
				};

				const vehicleSeries = action.payload.filters.filterStats.vehicleSeries.filter(series => series.imageUrl !== null);
				state.availableModels = getVehicleModels(vehicleSeries);
				state.availableSeries = vehicleSeries;
			})
			.addCase(moduleReset, () => {
				return initialState;
			});
	}
});

export const {vehicleMenuToggled} = vehicleSlice.actions;

export default vehicleSlice.reducer;
