import {reducer} from "../reducers";
import {configureStore} from "@reduxjs/toolkit";
import {tracker} from "../helper/tracker";

let store = configureStore({
	reducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(tracker),
});
export const updateStore = preloadedState => {
	return configureStore({
		reducer,
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(tracker),
		preloadedState
	});
};

export default store;
