// @flow

import type {ApplicationState} from "../reducers";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {IdleModal} from "../components/IdleModal";
import {disableIdleModal, performReset} from "../actions/config";

function mapStateToProps(state: ApplicationState) {
	return {
		shouldOpen: state.config.showIdleModal,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		hideModal: () => dispatch(disableIdleModal()),
		performReset: () => dispatch(performReset()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IdleModal));
