/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import "./Screensaver.scss";
import {connect} from "react-redux";
import arrow from "../themes/volvo/assets/images/arrow.svg";
import type {ApplicationState} from "../reducers";
import {useNavigate} from "react-router-dom-v5-compat";
import {videoLoaded} from "../reducers/screensaverReducer";
import {Loading} from "./Loading";

type Props = {
    video: ?string,
    videoUrl: ?string,
    saveVideo: string => void,
};

const Screensaver = (props: Props) => {
    React.useEffect(() => {
        if (!props.video && props.videoUrl) {
            const reader = new FileReader();
            reader.onload = function () {
                props.saveVideo(reader.result);
            };

            fetch(props.videoUrl, {}).then(res => {
                res.blob()
                    .then(blob => reader.readAsDataURL(blob));
            });
        }
    }, [props.videoUrl]);
    const navigate = useNavigate();

    if (props.videoUrl) {
        if (props.video) {
            return <div onClick={() => navigate("/")} className={"screensaver"}>
                <div>
                    Mit einem Klick <br/>
                    zu unserem Zubehörangebot
                    <img src={arrow} alt=""/>
                </div>
                <video autoPlay loop muted playsInline>
                    <source src={props.video}/>
                </video>
            </div>;
        } else {
            return <Loading/>;
        }
    } else {
        navigate("/");
        return null;
    }
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        video: state.screensaver.video,
        videoUrl: state.screensaver.videoUrl,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveVideo: base64 => dispatch(videoLoaded(base64))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screensaver);
