// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {getSearchPageProducts} from "../actions/content";
import type {PagedProduct} from "../reducers/contentReducer";
import type {SortOption} from "../components/CategoryProductsPage";
import {ProductsSearchPage} from "../components/ProductsSearchPage";

type SearchPageContainerProps = {
    onLoad: () => void,
    pagedProducts: PagedProduct[],
};

function mapStateToProps(state: ApplicationState, ownProps: SearchPageContainerProps) {
    return {
        fuzzy: state.filter.fuzzy,
        vehicleOption: state.filter.vehicleOption,
        pagedProducts: state.content.pagedProducts,
        isIdle: !state.content.requestQueue.length,
        amount: state.filter.amount,
        highlightColor: state.config.highlightColor,
        selectedCategoryKey: (state.content.selectedCategory || {}).key,
        wishlist: state.bookmarks.bookmarkedProducts,
        wishlistDisabled: state.config.noKeyboard,
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: SearchPageContainerProps) {
    return {
        onLoad: (fuzzy: string, page: number, sortOption: SortOption) => {
            dispatch(getSearchPageProducts(fuzzy, page, [sortOption]));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductsSearchPage));
