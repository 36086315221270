// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import SearchBar from "../components/SearchBar";
import {setSearchPhrase} from "../actions/filter";
import type {Dispatch} from "redux";

type SearchBarContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: SearchBarContainerProps) {
	return {
		searchPhrase: state.filter.fuzzy,
		isOpen: state.filter.isSearchBarOpen,
		contrastColor: state.config.contrastColor,
		selectedCategoryKey: (state.content.selectedCategory || {}).key,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: SearchBarContainerProps) {
	return {
		handleSubmit: (searchPhrase: string) => dispatch(setSearchPhrase(searchPhrase))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchBar));
