// @flow

import {ModuleInitiationExeption} from "../helper/CodeError";
import {CONFIG_ID_TYPE, INTENT, ProductService} from "../api/ProductService";
import type {Dispatch} from "redux";
import type {ApplicationState, GetState} from "../reducers";
import {customHistory} from "../helper/customHistory";
import {filterReducerInitialState} from "../reducers/filterReducer";
import {bookmarksReducerInitialState} from "../reducers/bookmarksReducer";
import {trackers} from "../helper/tracker";
import GoogleTracker from "../helper/tracker/GoogleTracker";
import PiwikTracker from "../helper/tracker/PiwikTracker";
import type {TrackingProperties} from "../reducers/configReducer";
import type Tracker from "../../../homepagemodul-2019/src/models/Tracker";
import {configSuccessGotten, idleModalToggled, moduleReset} from "../reducers/configReducer";
import StatisticApi from "../api/StatisticApi";
import {initialState as screenSaverReducerInitialState} from "../reducers/screensaverReducer";

export const GET_CONFIG = {
	REQUEST: "GET_CONFIG_REQUEST",
	SUCCESS: "GET_CONFIG_SUCCESS",
	FAILURE: "GET_CONFIG_FAILURE",
};

export const enableIdleModal = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		const {filter, bookmarks, screensaver} = getState();

		const isFilterReducerDirty = () => {
			const {vehicleOption, isSearchBarOpen, fuzzy} = filter;
			if (vehicleOption !== filterReducerInitialState.vehicleOption) return true;
			if (isSearchBarOpen !== filterReducerInitialState.isSearchBarOpen) return true;
			return fuzzy !== filterReducerInitialState.fuzzy;
		};

		const isBookmarksReducerDirty = () => {
			return bookmarks !== bookmarksReducerInitialState;
		};

		const isScreenSaverDirty = () => {
			const  {videoUrl} = screensaver;
			if(videoUrl !== screenSaverReducerInitialState.videoUrl) return true;
		};

		if (window.location.pathname !== "/" || isBookmarksReducerDirty() || isFilterReducerDirty() || (isScreenSaverDirty() && screensaver.videoUrl)) {
			dispatch(idleModalToggled(true));
		}
	};
};

export const disableIdleModal = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(idleModalToggled(false));
	};
};

export const performReset = () => {
	return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
		const {imageUrl, videoUrl, disableScreensaver} = getState().screensaver;
		dispatch(moduleReset());
		if (!disableScreensaver && (imageUrl || videoUrl)) customHistory.replace("/start");
		else customHistory.replace("/");
		dispatch(getConfig());
	};
};

export const getConfig = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch({
			type: GET_CONFIG.REQUEST
		});

		const { dealerId, garageId } = getState().config;

		if (!dealerId && !garageId) {
			dispatch({
				type: GET_CONFIG.FAILURE
			});
			throw new ModuleInitiationExeption("Missing configId - provide data-dealerid or data-garageid in root node", "400");
		}

		const configType = dealerId ? CONFIG_ID_TYPE.DEALER : CONFIG_ID_TYPE.GARAGE;
		const configId = dealerId || garageId;

		const StatisticClient = new StatisticApi(configId);
		StatisticClient.trackModuleLoad();

		const APIClient = new ProductService(configId, configType, INTENT.DEFAULT, getState().i18n.languageCode);

		APIClient.getConfig()
			.then(res => {
				const analyticTrackers = res.configurationDetails.trackingProperties.analyticsTrackers || [];
				const googleTrackers = analyticTrackers
					.filter((tracker: Tracker) => tracker.name === "google");
				const piwikTrackers = analyticTrackers
					.filter((tracker: Tracker) => tracker.name === "piwik");
				if (googleTrackers && googleTrackers.length > 0) {
					const trackingProperties: TrackingProperties = res.configurationDetails.trackingProperties || {};
					trackers.push(
						new GoogleTracker(
							googleTrackers.map(it => it.trackingKey),
							false,
							trackingProperties.dealerNo, trackingProperties.dealerName, trackingProperties.garageNo
						)
					);
				}
				if (piwikTrackers && piwikTrackers.length > 0) {
					const tracker = piwikTrackers[0];
					trackers.push(new PiwikTracker(tracker.trackingKey, tracker.trackingHostUrl, tracker.withEcommerce));
				}

				dispatch(configSuccessGotten(res));
			}).catch(err => {
				console.error(err);
				dispatch({
					type: GET_CONFIG.FAILURE,
					payload: err.message
				});
			});
	};
};
