// @flow

import type {GetState} from "../reducers";
import type {Dispatch} from "redux";
import {vehicleMenuToggled} from "../reducers/vehicleReducer";

export const toggleVehicleMenu = () => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		if (getState().vehicles.isMenuOpen) {
				dispatch(vehicleMenuToggled({isMenuOpen: true, isClosing: true}));
				setTimeout(() => {
					dispatch(vehicleMenuToggled({isMenuOpen: false, isClosing: false}));
				}, 500);
		} else {
			dispatch(vehicleMenuToggled({isMenuOpen: true, isClosing: false}));
		}
	};
};
