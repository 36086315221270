// @flow

import type {ApplicationState} from "../reducers";
import type {Dispatch} from "redux";
import {connect} from "react-redux";
import {toggleVehicleMenu} from "../actions/vehicles";
import {CarSelector} from "../components/CarSelector";
import {setVehicle} from "../actions/filter";

type CarSelectorContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: CarSelectorContainerProps) {
	return {
		availableSeries: state.vehicles.availableSeries,
		availableModels: state.vehicles.availableModels,
		isMenuOpen: state.vehicles.isMenuOpen,
		isClosing: state.vehicles.isClosing,
		highlightColor: state.config.highlightColor,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CarSelectorContainerProps) {
	return {
		toggleVehicleMenu: () => dispatch(toggleVehicleMenu()),
		handleVehicleSelection: vehicle => {
			dispatch(setVehicle(vehicle.series, vehicle.model));
			dispatch(toggleVehicleMenu());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CarSelector);
