// @flow
import React from "react";
import {createRoot} from "react-dom/client";
import Root from "./Root";
import "./i18n/i18n.js";
import AppContainer from "./container/AppContainer";
import RootNodeProvider from "./helper/RootNodeProvider";

const root = createRoot(RootNodeProvider.getPosRootNode());

root.render(
	<Root>
		<AppContainer/>
	</Root>
);
