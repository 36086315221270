// @flow
import * as React from "react";
import {withIdleTimer} from "react-idle-timer";
import IdleModalContainer from "../container/IdleModalContainer";

type Props = {
	showIdleModal: () => void
};

const IdleTimerComponent = props => {
	return props.children;
};

const IdleTimer = withIdleTimer(IdleTimerComponent);

export const Timeout = (props: Props) => {
	// eslint-disable-next-line no-unused-vars
	let idleTimer = React.useRef();

	const defaultTimeoutMinutes = 5;

	return (
		<React.Fragment>
			<IdleModalContainer/>
			<IdleTimer
				ref={ref => { idleTimer = ref; }}
				timeout={1000 * 60 * defaultTimeoutMinutes}
				onActive={() => {}}
				onIdle={() => props.showIdleModal()}
				onAction={() => {}}
				debounce={250}
			/>
		</React.Fragment>
	);
};
