// @flow
import type {Dispatch} from "redux";
import type {ApplicationState} from "../reducers";
import Header from "../components/Header";
import {connect} from "react-redux";
import {toggleBookmarks, toggleI18nSelector} from "../actions/bookmarks";
import {toggleSearchBar} from "../actions/filter";
import {toggleVehicleMenu} from "../actions/vehicles";
import {resetBrandInstance} from "../actions/brand";

type HeaderContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: HeaderContainerProps) {
	return {
		vehicleOption: state.filter.vehicleOption,
		bookmarksLength: state.bookmarks.bookmarkedProducts.length,
		availableLanguages: state.i18n.availableLanguages,
		hasMultipleBrands: state.config.garageId && state.config.brandInstances.length > 1,
		contrastColor: state.config.contrastColor,
		disableScreensaver: state.screensaver.disableScreensaver,
		disableSearchbar: state.config.noKeyboard,
		disableWishlist: state.config.noKeyboard,
		logoUrl: state.config.manufacturerLogoUrl
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: HeaderContainerProps) {
	return {
		toggleVehicleMenu: () => dispatch(toggleVehicleMenu()),
		toggleSearchBar: () => dispatch(toggleSearchBar()),
		toggleBookmarks: () => dispatch(toggleBookmarks()),
		toggleI18nSelector: () => dispatch(toggleI18nSelector()),
		resetBrand: () => {
			dispatch(resetBrandInstance());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

